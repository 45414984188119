import React from "react";
import { useNavigate } from "react-router-dom";
import "./Blocks.scss";

export const Blocks = () => {
  const navigate = useNavigate();

  return (
    <div className="section_4__blocks">
      <div className="block" onClick={() => navigate("#")}>
        <div className="block__img">
          <svg
            width="24"
            height="24"
            viewBox="0 0 37 37"
            className="svg"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M18.1775 0C8.14354 0 0 8.14354 0 18.1775C0 28.2115 8.14354 36.3551 18.1775 36.3551C28.2115 36.3551 36.3551 28.2115 36.3551 18.1775C36.3551 8.14354 28.2115 0 18.1775 0ZM31.738 12.9424L26.6846 15.0328C25.7576 12.5607 23.8126 10.5975 21.3223 9.68863L23.4127 4.63527C27.23 6.08948 30.2656 9.12513 31.738 12.9424ZM18.1775 23.6308C15.1601 23.6308 12.7243 21.195 12.7243 18.1775C12.7243 15.1601 15.1601 12.7243 18.1775 12.7243C21.195 12.7243 23.6308 15.1601 23.6308 18.1775C23.6308 21.195 21.195 23.6308 18.1775 23.6308ZM12.9606 4.6171L15.0874 9.67045C12.5789 10.5793 10.5975 12.5607 9.67045 15.0692L4.6171 12.9606C6.08948 9.12513 9.12513 6.08948 12.9606 4.6171ZM4.6171 23.3945L9.67045 21.3041C10.5975 23.8126 12.5607 25.7758 15.0692 26.6846L12.9424 31.738C9.12513 30.2656 6.08948 27.23 4.6171 23.3945ZM23.4127 31.738L21.3223 26.6846C23.8126 25.7576 25.7758 23.7944 26.6846 21.2859L31.738 23.4127C30.2656 27.23 27.23 30.2656 23.4127 31.738Z" />
          </svg>
        </div>
        <p className="block__text">View details</p>
        <svg
          width="8"
          height="13"
          viewBox="0 0 8 13"
          className="svg svg--green"
          fill="#29CC6A"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M5.23819 6.80908L0.945298 2.51619C0.554774 2.12566 0.554774 1.4925 0.945298 1.10198C1.33582 0.711452 1.96899 0.711452 2.35951 1.10198L7.35951 6.10198C7.75004 6.4925 7.75004 7.12566 7.35951 7.51619L2.35951 12.5162C1.96899 12.9067 1.33582 12.9067 0.945298 12.5162C0.554774 12.1257 0.554774 11.4925 0.945298 11.102L5.23819 6.80908Z" />
        </svg>
      </div>
      <div className="block" onClick={() => navigate("#")}>
        <div className="block__img">
          <svg
            width="24"
            height="24"
            viewBox="0 0 36 36"
            className="svg"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M13.7522 10.152C14.1662 9.252 14.4002 8.262 14.4002 7.2C14.4002 3.222 11.1782 0 7.20024 0C3.22224 0 0.000244141 3.222 0.000244141 7.2C0.000244141 11.178 3.22224 14.4 7.20024 14.4C8.26224 14.4 9.25224 14.166 10.1522 13.752L14.4002 18L10.1522 22.248C9.25224 21.834 8.26224 21.6 7.20024 21.6C3.22224 21.6 0.000244141 24.822 0.000244141 28.8C0.000244141 32.778 3.22224 36 7.20024 36C11.1782 36 14.4002 32.778 14.4002 28.8C14.4002 27.738 14.1662 26.748 13.7522 25.848L18.0002 21.6L30.6002 34.2H36.0002V32.4L13.7522 10.152ZM7.20024 10.8C5.22024 10.8 3.60024 9.198 3.60024 7.2C3.60024 5.202 5.22024 3.6 7.20024 3.6C9.18024 3.6 10.8002 5.202 10.8002 7.2C10.8002 9.198 9.18024 10.8 7.20024 10.8ZM7.20024 32.4C5.22024 32.4 3.60024 30.798 3.60024 28.8C3.60024 26.802 5.22024 25.2 7.20024 25.2C9.18024 25.2 10.8002 26.802 10.8002 28.8C10.8002 30.798 9.18024 32.4 7.20024 32.4ZM18.0002 18.9C17.4962 18.9 17.1002 18.504 17.1002 18C17.1002 17.496 17.4962 17.1 18.0002 17.1C18.5042 17.1 18.9002 17.496 18.9002 18C18.9002 18.504 18.5042 18.9 18.0002 18.9ZM30.6002 1.8L19.8002 12.6L23.4002 16.2L36.0002 3.6V1.8H30.6002Z" />
          </svg>
        </div>
        <p className="block__text">View details</p>
        <svg
          width="8"
          height="13"
          viewBox="0 0 8 13"
          className="svg svg--green"
          fill="#29CC6A"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M5.23819 6.80908L0.945298 2.51619C0.554774 2.12566 0.554774 1.4925 0.945298 1.10198C1.33582 0.711452 1.96899 0.711452 2.35951 1.10198L7.35951 6.10198C7.75004 6.4925 7.75004 7.12566 7.35951 7.51619L2.35951 12.5162C1.96899 12.9067 1.33582 12.9067 0.945298 12.5162C0.554774 12.1257 0.554774 11.4925 0.945298 11.102L5.23819 6.80908Z" />
        </svg>
      </div>
      <div className="block" onClick={() => navigate("#")}>
        <div className="block__img">
          <svg
            width="24"
            height="28"
            viewBox="0 0 37 44"
            className="svg"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M7.40012 33C7.40012 34.0083 8.23262 34.8333 9.25012 34.8333H11.1001V41.25C11.1001 42.7717 12.3396 44 13.8751 44C15.4106 44 16.6501 42.7717 16.6501 41.25V34.8333H20.3501V41.25C20.3501 42.7717 21.5896 44 23.1251 44C24.6606 44 25.9001 42.7717 25.9001 41.25V34.8333H27.7501C28.7676 34.8333 29.6001 34.0083 29.6001 33V14.6667H7.40012V33ZM2.77512 14.6667C1.23962 14.6667 0.00012207 15.895 0.00012207 17.4167V30.25C0.00012207 31.7717 1.23962 33 2.77512 33C4.31062 33 5.55012 31.7717 5.55012 30.25V17.4167C5.55012 15.895 4.31062 14.6667 2.77512 14.6667ZM34.2251 14.6667C32.6896 14.6667 31.4501 15.895 31.4501 17.4167V30.25C31.4501 31.7717 32.6896 33 34.2251 33C35.7606 33 37.0001 31.7717 37.0001 30.25V17.4167C37.0001 15.895 35.7606 14.6667 34.2251 14.6667ZM25.0306 3.96L27.4356 1.57667C27.8056 1.21 27.8056 0.641667 27.4356 0.275C27.0656 -0.0916667 26.4921 -0.0916667 26.1221 0.275L23.3841 2.98833C21.9226 2.255 20.2576 1.83333 18.5001 1.83333C16.7241 1.83333 15.0591 2.255 13.5791 2.98833L10.8226 0.275C10.4526 -0.0916667 9.87912 -0.0916667 9.50912 0.275C9.13912 0.641667 9.13912 1.21 9.50912 1.57667L11.9326 3.97833C9.19462 5.97667 7.40012 9.185 7.40012 12.8333H29.6001C29.6001 9.185 27.8056 5.95833 25.0306 3.96ZM14.8001 9.16667H12.9501V7.33333H14.8001V9.16667ZM24.0501 9.16667H22.2001V7.33333H24.0501V9.16667Z" />
          </svg>
        </div>
        <p className="block__text">View details</p>
        <svg
          width="8"
          height="13"
          viewBox="0 0 8 13"
          className="svg svg--green"
          fill="#29CC6A"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M5.23819 6.80908L0.945298 2.51619C0.554774 2.12566 0.554774 1.4925 0.945298 1.10198C1.33582 0.711452 1.96899 0.711452 2.35951 1.10198L7.35951 6.10198C7.75004 6.4925 7.75004 7.12566 7.35951 7.51619L2.35951 12.5162C1.96899 12.9067 1.33582 12.9067 0.945298 12.5162C0.554774 12.1257 0.554774 11.4925 0.945298 11.102L5.23819 6.80908Z" />
        </svg>
      </div>
      <div className="block" onClick={() => navigate("#")}>
        <div className="block__img">
          <svg
            width="24"
            height="24"
            viewBox="0 0 37 37"
            className="svg"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M21.9038 28.3206L21.8856 28.3388L17.523 23.9762L19.4499 22.0494L21.9038 24.5033L26.43 19.9953L28.3568 21.9221L21.922 28.357L21.9038 28.3206ZM18.1774 0C8.17977 0 -0.00012207 8.17989 -0.00012207 18.1775C-0.00012207 28.1752 8.17977 36.3551 18.1774 36.3551C28.1751 36.3551 36.355 28.1752 36.355 18.1775C36.355 8.17989 28.1751 0 18.1774 0ZM9.70669 7.77999L12.2697 10.343L14.8328 7.77999L16.7596 9.70681L14.1965 12.2698L16.7596 14.8329L14.8328 16.7597L12.2697 14.1967L9.70669 16.7597L7.77987 14.8329L10.3429 12.2698L7.77987 9.70681L9.70669 7.77999ZM18.1774 32.7196C14.1784 32.7196 10.5429 31.0836 7.81622 28.5387L28.5386 7.81634C31.0835 10.543 32.7195 14.1785 32.7195 18.1775C32.7195 26.1757 26.1755 32.7196 18.1774 32.7196Z" />
          </svg>
        </div>
        <p className="block__text">View details</p>
        <svg
          width="8"
          height="13"
          viewBox="0 0 8 13"
          className="svg svg--green"
          fill="#29CC6A"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M5.23819 6.80908L0.945298 2.51619C0.554774 2.12566 0.554774 1.4925 0.945298 1.10198C1.33582 0.711452 1.96899 0.711452 2.35951 1.10198L7.35951 6.10198C7.75004 6.4925 7.75004 7.12566 7.35951 7.51619L2.35951 12.5162C1.96899 12.9067 1.33582 12.9067 0.945298 12.5162C0.554774 12.1257 0.554774 11.4925 0.945298 11.102L5.23819 6.80908Z" />
        </svg>
      </div>
    </div>
  );
};
