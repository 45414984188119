export const Section2_Data = [
  {
    id: "01",
    title: "Arcu non odio",
    content:
      "Sapien nec sagittis aliquam malesuada bibendum. Tempor nec feugiat nisl pretium.",
  },
  {
    id: "02",
    title: "Eget gravida cum sociis",
    content:
      "Sapien nec sagittis aliquam malesuada bibendum. Tempor nec feugiat nisl pretium.",
  },
  {
    id: "03",
    title: "Massa massa ultricies",
    content:
      "Sapien nec sagittis aliquam malesuada bibendum. Tempor nec feugiat nisl pretium.",
  },
];
